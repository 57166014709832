.little-logo {
  display: inline-block;
  height: 3em;
  width: 3em;

  img {
    width: 100%;
    height: 100%;
  }
}
