body {
  margin: 0px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #111;
}

.banner {
  position: relative;
  font-family: monospace;
  font-weight: 100;
  border-bottom: none;
  margin: 0;
  padding: 0.2em 0 2em;
  background: #333;
  color: #F5F5F5;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
}
     
.banner h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24pt;
  line-height: 1;
  letter-spacing: -1px;
}

.logo {
  height: 1.2em;
  padding: 0 1em; 
}

.body-container {
  margin: 1em;
}

.comp-selector-bar {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: -2px;
  padding: 1em 0;
  background-color: #222;
  border: 1px solid black;
  color: #ccc;
}

.warning-alert {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.5em;
  color: #721c24;
  padding: 1em;
  margin-bottom: 1em;
}

.footer {
  border-top: 1px solid #ccc;
  margin: 2em 0 0 0;
  padding: 1em 3em;
  text-align: right;
  font-size: large;
  background-color: #fafafa;

  a {
    color: #007bff;
    text-decoration: none;
  }
}