.wca-login-container {
  padding: 0.5em;
}

.login-button {
  display: inline-block;
  margin: 0.3em;
  padding: 0.3em 1em ;
  font-family: monospace;

  background-color: #5cb85c;
  border: 1px solid #4cae4c;
  border-radius: 0.5em;
  text-decoration: none;
  color: #fff;
  font-size: 10pt;
  font-weight: 400;
}
