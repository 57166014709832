.event-link-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .event-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaa;
    background-color: #f2f2f2;
    box-shadow: 1px 1px 1px #aaa;
    border-radius: 0.5em;

    margin: 0.3em 2em;
    padding: 0.5em 1em;

    text-decoration: none;
    color: #444;

    .little-logo {
      border-right: 1px solid #aaa;
      padding-right: 1em;
    }

    .event-link-label {
      padding-left: 1em;
    }
  }
}