.wca-login-container {
  padding: .5em;
}

.login-button {
  color: #fff;
  background-color: #5cb85c;
  border: 1px solid #4cae4c;
  border-radius: .5em;
  margin: .3em;
  padding: .3em 1em;
  font-family: monospace;
  font-size: 10pt;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.quicket-login label, .quicket-login input {
  margin: 0 1em;
  padding: 0 1em;
  display: inline-block;
}

.quicket-login form a {
  color: #3093e9;
  font-weight: bold;
}

.comp-selector-container select {
  margin: 0 1em;
}

.comp-selector-error {
  color: #ff6572;
}

.little-logo {
  height: 3em;
  width: 3em;
  display: inline-block;
}

.little-logo img {
  width: 100%;
  height: 100%;
}

.event-link-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.event-link-container .event-link {
  color: #444;
  background-color: #f2f2f2;
  border: 1px solid #aaa;
  border-radius: .5em;
  justify-content: center;
  align-items: center;
  margin: .3em 2em;
  padding: .5em 1em;
  text-decoration: none;
  display: flex;
  box-shadow: 1px 1px 1px #aaa;
}

.event-link-container .event-link .little-logo {
  border-right: 1px solid #aaa;
  padding-right: 1em;
}

.event-link-container .event-link .event-link-label {
  padding-left: 1em;
}

.guest-list {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.guest-list table {
  border-collapse: collapse;
  width: 100%;
}

.guest-list .status-column {
  text-align: center;
}

.guest-list table th {
  color: #fff;
}

.guest-list table td, .guest-list table th {
  border: 1px solid #ccc;
  padding: .5em 1.5em;
}

.guest-list table td {
  font-size: .8em;
}

.guest-list .status-tag {
  text-align: center;
  color: #fff;
  background-color: #5cb85c;
  border-radius: .5em;
  margin: 0 .3em;
  padding: .4em .6em;
  font-size: 70%;
  font-weight: 700;
  display: inline-block;
}

.guest-list .pending th {
  background-color: #007bff;
}

.guest-list .awaiting th {
  background-color: #dc3545;
}

.guest-list .unregistered th {
  background-color: #ffc107;
}

.guest-list .accepted-not-paid th {
  background-color: #17a2b8;
}

.guest-list .accepted-paid th {
  background-color: #5cb85c;
}

.guest-list .deleted th {
  background-color: #6c757d;
}

.guest-list .add-registration-button {
  color: #444;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: .5em;
  margin-right: .5em;
  padding: .3em .5em;
  text-decoration: none;
}

body {
  color: #111;
  margin: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.banner {
  color: #f5f5f5;
  text-align: center;
  text-shadow: 0 1px #0000001a;
  background: #333;
  border-bottom: none;
  align-items: center;
  margin: 0;
  padding: .2em 0 2em;
  font-family: monospace;
  font-weight: 100;
  position: relative;
}

.banner h1 {
  letter-spacing: -1px;
  justify-content: center;
  align-items: center;
  font-size: 24pt;
  line-height: 1;
  display: flex;
}

.logo {
  height: 1.2em;
  padding: 0 1em;
}

.body-container {
  margin: 1em;
}

.comp-selector-bar {
  width: 100%;
  color: #ccc;
  background-color: #222;
  border: 1px solid #000;
  justify-content: space-evenly;
  margin: -2px;
  padding: 1em 0;
  display: flex;
}

.warning-alert {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: .5em;
  margin-bottom: 1em;
  padding: 1em;
}

.footer {
  text-align: right;
  background-color: #fafafa;
  border-top: 1px solid #ccc;
  margin: 2em 0 0;
  padding: 1em 3em;
  font-size: large;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

/*# sourceMappingURL=index.2a5a6943.css.map */
